import React from 'react';
import { PageProps } from '../typing/Page';
import Layout from '../components/Layout';
import { border, fontSize, lineHeight, spacer } from '../styles/variables';
import PageTitle from '../components/PageTitle';
import styled from '@emotion/styled';
import { getRemSize } from '../styles/mixins';
import { breakpointUp } from '../styles/mq';
import { graphql } from 'gatsby';
import { getTranslation } from '../utils/translations';

type StyledSectionProps = {
	hasBorder?: boolean;
};

const StyledSection = styled.section`
	padding-top: ${({ hasBorder }: StyledSectionProps) => (hasBorder ? spacer['9'] : '0')};
	border-top: ${({ hasBorder }: StyledSectionProps) => (hasBorder ? border.fullMuted : 'none')};
	font-size: ${fontSize.base};

	&:not(:last-child) {
		margin-bottom: ${spacer['9']};

		${breakpointUp('md')} {
			margin-bottom: ${spacer['11']};
		}
	}

	${breakpointUp('md')} {
		padding-top: ${({ hasBorder }: StyledSectionProps) => (hasBorder ? spacer['11'] : '0')};
		font-size: ${fontSize.md};
	}
`;

const StyledSectionTitle = styled.h2`
	font-size: ${fontSize.lg};

	${breakpointUp('md')} {
		font-size: ${fontSize.h2};
	}
`;

const StyledText = styled.p`
	margin-bottom: 0;
	font-size: ${fontSize.md};
	font-weight: bold;

	${breakpointUp('md')} {
		font-size: ${getRemSize(24)};
	}

	${breakpointUp('xxl')} {
		font-size: ${getRemSize(28)};
	}
`;

const StyledList = styled.ul`
	padding-left: 0;
	list-style: none;

	> li {
		padding: ${spacer['1']} 0;
		line-height: ${lineHeight.small};
	}
`;

type Services = {
	atelier: string[];
	tech: string[];
};

type AboutData = {
	about: string;
	services: Services;
};

const AboutTemplate: React.FC<PageProps<AboutData, GatsbyTypes.AboutQuery>> = ({ path, data, pageContext }) => {
	return (
		<Layout
			path={path}
			pageContext={pageContext}
		>
			<StyledSection>
				<PageTitle title={pageContext.title} />
				<StyledText>{pageContext.data.about}</StyledText>
			</StyledSection>
			<StyledSection hasBorder={true}>
				<StyledSectionTitle>{getTranslation(data, 'whatWeDo')}</StyledSectionTitle>
				<StyledList>
					{pageContext.data.services.atelier.map((service, i) => (
						<li key={i.toString()}>{service}</li>
					))}
				</StyledList>
			</StyledSection>
			<StyledSection hasBorder={true}>
				<StyledSectionTitle>{getTranslation(data, 'production')}</StyledSectionTitle>
				<StyledList>
					{pageContext.data.services.tech.map((service, i) => (
						<li key={i.toString()}>{service}</li>
					))}
				</StyledList>
			</StyledSection>
		</Layout>
	);
};

export const query = graphql`
	query About($lang: String!) {
		translations: allFile(filter: { name: { eq: $lang }, extension: { eq: "json" } }) {
			nodes {
				childTranslationsJson {
					whatWeDo
					production
				}
			}
		}
	}
`;

export default AboutTemplate;
